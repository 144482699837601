import { makeStyles } from "@material-ui/core";
import { api, dataProvider } from "data";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS } from "ui/pages/schedule/redux/actions";
import { roundOff } from "utils/Utils";
import { is_empty } from "utils/validations";
import {
  bookings_feedbacks_chart,
  published_feedbacks,
} from "./module_constants";
import module_style from "../Style.module.css";
import {
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__RESET_STATE,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__EDIT_REVIEW,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DUPLICATE_REVIEW,
  EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DELETE_REVIEW,
} from "../redux/actions";
import { useNotifications } from "utils/hooks";
import constants from "constants/constants";
import { useRefresh } from "react-admin";
import EmptyListIcon from "assets/vectors/infoModal/search.svg";
import { listing_miscellaneous_data_types } from "features/Listings/Listings.constants";

const useStyles = makeStyles((theme) => ({
  veryLow: {
    color: theme?.palette?.coral_red?.main,
  },
}));

let bookings_feedback_text, bookings_feedback_percentage;

export const useFeedbacks = () => {
  const classes = useStyles();
  const is_desktop = useDesktopMediaQuery();
  const dispatch = useDispatch();
  const { notify } = useNotifications();
  const refetch = useRefresh();

  // fetch offerings
  const offerings = useSelector((state) => state.schedule.live);
  const past_offerings = useSelector((state) => state.schedule.past);
  const sold_offerings = useSelector((state) => state.schedule.soldout);
  const offerings_processing = offerings.isLoading;

  const fetchOfferings = () => {
    dispatch({
      type: EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
      payload: { fetchAll: true },
    });
  };

  const [active_offerings, set_active_offerings] = React.useState(null);
  const [filter_offerings, set_filter_offerings] = React.useState(null);

  React.useEffect(() => {
    if (!is_empty(offerings.data)) {
      let temp = offerings?.data.map((listing) => ({
        ...listing,
        id: listing.uuid,
        label: listing.title,
        value: listing.uuid,
      }));
      set_active_offerings(temp);

      let all_offerings = offerings.data;
      if (!is_empty(past_offerings?.data)) {
        all_offerings.concat(past_offerings.data);
      }
      if (!is_empty(sold_offerings?.data)) {
        all_offerings.concat(sold_offerings.data);
      }
      let temp1 = all_offerings.map((listing) => ({
        id: listing.uuid,
        label: listing.title,
        value: listing.uuid,
      }));
      set_filter_offerings(temp1);
    }
  }, [offerings]);

  // fetch feedback questions

  const [ratings, set_ratings] = React.useState(null);
  const [overall_rating, set_overall_rating] = React.useState(0);
  const [processing, set_processing] = React.useState(true);

  const fetchRatings = async () => {
    const low_percentage = 34;
    const medium_percentage = 66;
    const low_booking_count = 20;
    try {
      let feedback_questions = getFeedbackQuestions();
      if (is_empty(feedback_questions)) {
        feedback_questions = await dataProvider.custom_request(
          api.feedbacks.fetch_feedback_questions,
          "GET"
        );
      }

      if (!is_empty(feedback_questions?.data?.data?.rating)) {
        setFeedbackQuestions(feedback_questions);

        if (feedback_questions?.data?.feedback_count > 0) {
          let sum = 0;
          for (let item of feedback_questions.data.data.rating) {
            sum += item.id * item.count;
          }
          const bookings_count = feedback_questions.data.bookings_count;
          const feedback_count = feedback_questions.data.feedback_count;

          feedback_questions.data.data.rating.map(
            (item) =>
              (item.tooltip = `${item.count} rating${
                item.count > 1 ? "s" : ""
              }(${roundOff((item.count / feedback_count) * 100, 2)}%)`)
          );

          bookings_feedbacks_chart[0].value = bookings_count;
          bookings_feedbacks_chart[0].tooltip =
            bookings_count > feedback_count
              ? `${
                  bookings_count - feedback_count
                } feedbacks not received / ${bookings_count} total bookings`
              : `${bookings_count} total bookings`;

          bookings_feedbacks_chart[1].value = feedback_count;
          bookings_feedbacks_chart[1].tooltip = `${feedback_count} feedback received / ${bookings_count} total bookings`;

          bookings_feedback_percentage = roundOff(
            (bookings_feedbacks_chart[1].value /
              bookings_feedbacks_chart[0].value) *
              100,
            2
          );
          if (bookings_feedback_percentage < low_percentage)
            bookings_feedback_text = (
              <span>
                The number of feedback received is{" "}
                <span className={classes.veryLow}>very low</span>. Conclusions
                drawn may not be fully accurate.
              </span>
            );
          else if (bookings_feedback_percentage < medium_percentage)
            bookings_feedback_text =
              "The number of feedback received should be more to draw better conclusions from the data.";
          else if (bookings_count < low_booking_count)
            bookings_feedback_text =
              "A good percentage of users have sent feedback. But because of fewer bookings, conclusions drawn may not be fully accurate.";
          else
            bookings_feedback_text =
              "A good number of users have sent feedback. Conclusions can be drawn using the data provided.";

          set_overall_rating(
            roundOff(sum / feedback_questions.data.feedback_count, 2)
          );
        }
        set_ratings(feedback_questions.data.data);
        set_processing(false);
      }
    } catch (err) {
      console.log("Feedback []", err);
    }
  };

  const setFeedbackQuestions = (feedback_questions) => {
    sessionStorage.setItem(
      "feedback_questions",
      JSON.stringify(feedback_questions)
    );
  };

  const getFeedbackQuestions = () => {
    return JSON.parse(sessionStorage.getItem("feedback_questions"));
  };

  // user feedbacks

  const handleAddToOffering = async (record) => {
    try {
      const feedback_questions = getFeedbackQuestions()?.data.data;
      let question = feedback_questions?.question_bank[record.rating][0];
      let answer = record.feedback_data.response.find(
        (item) => item.id == question.id
      );
      if (is_empty(answer)) return notify("No answer provided!", "error");
      let payload = {
        listing_uuid: record?.listing_uuid,
        type: listing_miscellaneous_data_types.reviews,
        data: {
          name: record?.customer_name,
          image: "",
          rating: record?.rating,
          source: constants.listing_review_source.creator_tool,
          status: constants.listing_review_status.live,
          description: answer?.message,
          sub_heading: "",
        },
      };
      await dataProvider.custom_request(
        api.listing_miscellaneous_data.create,
        "POST",
        payload
      );
      notify("Successfully Added!", "info");
    } catch (err) {
      const message = err?.body?.message
        ? err?.body?.message
        : constants.error_message;
      console.log("published_feedback handleAddToOffering", err);
      notify(message, "error");
    }
  };

  //published review
  const published_review_data = useSelector(
    (state) => state.exlyFeedback.published_reviews
  );

  const show_modal = published_review_data.show_modal;
  const show_delete_modal = published_review_data.show_delete_modal;
  const error_object = published_review_data.error_object;
  const review_payload = published_review_data.review_payload;
  const show_offerings = published_review_data.show_offerings;

  const isValidPayload = (payload) => {
    let error_obj = {};
    if (is_empty(payload?.name)) {
      error_obj.name = true;
    }
    if (is_empty(payload?.description)) {
      error_obj.description = true;
    }
    if (!is_empty(error_obj)) {
      dispatch({
        type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
        payload: {
          key: "error_object",
          value: error_obj,
        },
      });
      return false;
    }
    return true;
  };

  const handleModalSave = async (draft = false) => {
    try {
      if (show_offerings) {
        if (is_empty(review_payload?.listing_uuid)) {
          return notify("Please select a listing!");
        }
        dispatch({
          type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
          payload: {
            key: "show_offerings",
            value: false,
          },
        });
      } else {
        // validation for publish
        if (!draft && !isValidPayload(review_payload)) {
          return notify("Invalid values", "error");
        }

        let review_status = constants.listing_review_status.live;
        if (draft) {
          review_status = constants.listing_review_status.draft;
        } else if (
          review_payload.status === constants.listing_review_status.hidden
        ) {
          review_status = constants.listing_review_status.hidden;
        }

        let api_resource;
        let payload = {
          listing_uuid: review_payload?.listing_uuid,
          type: listing_miscellaneous_data_types.reviews,
          data: {
            name: review_payload?.name,
            image: review_payload?.image,
            rating: review_payload?.rating,
            source: constants.listing_review_source.creator_tool,
            status: review_status,
            description: review_payload?.description,
            sub_heading: review_payload?.sub_heading,
          },
        };
        if (is_empty(review_payload?.uuid)) {
          api_resource = api.listing_miscellaneous_data.create;
        } else {
          api_resource = api.listing_miscellaneous_data.update;
          payload = {
            uuid: review_payload?.uuid,
            updated_values: {
              data: payload.data,
            },
          };
        }
        const status = await dataProvider.custom_request(
          api_resource,
          "POST",
          payload
        );
        let notif_msg =
          !draft && is_empty(review_payload?.uuid)
            ? "Review is now live!"
            : status.message;
        if (draft) notif_msg = "Saved as draft!";
        notify(notif_msg, "success");
        if (status?.status === 200) {
          refetch();
          handleModalClose();
        }
      }
    } catch (err) {
      const message = err?.body?.message
        ? err?.body?.message
        : constants.error_message;
      console.log("published_feedback handleModalSave", message);
      notify(message, "error");
    }
  };

  const handleStatusChange = async (record) => {
    try {
      if (
        record.status === constants.listing_review_status.draft &&
        !isValidPayload(record.data)
      ) {
        dispatch({
          type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
          payload: {
            key: "error_object",
            value: {},
          },
        });
        return notify("Incomplete feedback!", "error");
      }
      let payload = {
        uuid: record?.uuid,
        updated_values: {
          data: {
            ...record.data,
            status:
              record.status === constants.listing_review_status.draft
                ? constants.listing_review_status.live
                : record.status === constants.listing_review_status.live
                ? constants.listing_review_status.hidden
                : constants.listing_review_status.live,
          },
        },
      };
      const status = await dataProvider.custom_request(
        api.listing_miscellaneous_data.update,
        "POST",
        payload
      );
      let notif_msg = status.message;
      if (record.status === constants.listing_review_status.live) {
        notif_msg = "Review has been hidden";
      }

      if (record.status === constants.listing_review_status.draft) {
        notif_msg = "Review is now live!";
      }

      if (record.status === constants.listing_review_status.hidden) {
        notif_msg = "Review is now visible!";
      }
      notify(notif_msg, "success");
      if (status?.status === 200) {
        refetch();
      }
    } catch (err) {
      const message = err?.body?.message
        ? err?.body?.message
        : constants.error_message;
      console.log("published_feedback handleStatusChange", message);
      notify(message, "error");
    }
  };

  const MORE_ACTIONS = [
    {
      label: "Edit",
      onClick: (record, _rows, _setDrawerConfig, setActionsDrawerConfig) => {
        if (!is_desktop) setActionsDrawerConfig({ row: null, open: false });
        dispatch({
          type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__EDIT_REVIEW,
          payload: record,
        });
      },
    },
    {
      renderCustomAction: (record) => (
        <div
          className={`p-2 p-md-0`}
          onClick={() => {
            handleStatusChange(record);
          }}
        >
          {record.status === constants.listing_review_status.draft
            ? "Publish"
            : record.status === constants.listing_review_status.live
            ? "Hide"
            : "Show"}
        </div>
      ),
    },
    {
      label: "Duplicate",
      onClick: (record, _rows, _setDrawerConfig, setActionsDrawerConfig) => {
        if (!is_desktop) setActionsDrawerConfig({ row: null, open: false });
        dispatch({
          type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DUPLICATE_REVIEW,
          payload: record,
        });
      },
    },
    {
      label: <div className={`text-danger`}>Delete</div>,
      onClick: (record, _rows, _setDrawerConfig, setActionsDrawerConfig) => {
        if (!is_desktop) setActionsDrawerConfig({ row: null, open: false });
        dispatch({
          type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__DELETE_REVIEW,
          payload: record?.uuid,
        });
      },
    },
  ];

  const handleReviewDelete = async () => {
    try {
      const status = await dataProvider.custom_request(
        api.listing_miscellaneous_data.delete,
        "POST",
        { uuid: review_payload?.uuid }
      );
      notify(status.message, "info");
      if (status?.status === 200) {
        handleModalClose();
        refetch();
      }
    } catch (err) {
      const message = err?.body?.message
        ? err?.body?.message
        : constants.error_message;
      console.log("published_feedback handleReviewDelete", message);
      notify(message, "error");
    }
  };

  const handleModalClose = () => {
    dispatch({
      type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__RESET_STATE,
    });
  };

  const handleModalOpen = (filterValues) => {
    if (!is_empty(filterValues?.listing__uuid)) {
      dispatch({
        type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
        payload: {
          key: "show_offerings",
          value: is_empty(filterValues?.listing__uuid),
        },
      });
      updateData(filterValues?.listing__uuid, "listing_uuid");
    }
    dispatch({
      type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
      payload: {
        key: "show_modal",
        value: true,
      },
    });
  };

  const getPrimaryActionText = () => {
    let text;
    if (show_offerings) {
      text = "Next";
    } else {
      if (!is_empty(review_payload?.uuid)) {
        if (review_payload?.status === constants.listing_review_status.draft) {
          text = "Publish feedback";
        } else {
          text = "Save";
        }
      } else text = "Publish feedback";
    }
    return text;
  };

  const getReviewModalProps = () => {
    const show_secondary_btn =
      !show_offerings &&
      review_payload?.status !== constants.listing_review_status.live &&
      review_payload?.status !== constants.listing_review_status.hidden;

    let review_modal_props = {
      title: "Add feedback",
      showSecondaryBtn: show_secondary_btn,
      primaryBtnFullWidth: show_offerings,
      onClose: handleModalClose,
      primaryBtnText: getPrimaryActionText(),
      secondaryBtnText: "Save as draft",
      modal_props: {
        modalPaperClassName: module_style.review_modal,
      },
      mobile_modal_props: show_secondary_btn ? {} : { secondaryAction: false },
    };

    return review_modal_props;
  };

  const updateData = (value, key) => {
    if (error_object?.[key]) {
      dispatch({
        type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
        payload: {
          key: "error_object",
          value: { ...error_object, [key]: false },
        },
      });
    }
    dispatch({
      type: EXLY_FEEDBACKS__PUBLISHED_REVIEWS__UPDATE_STATE,
      payload: {
        key: "review_payload",
        value: { ...review_payload, [key]: value },
      },
    });
  };

  const empty_state_props = {
    page_title: "Feedback",
    content_title: "No Reviews Displayed Yet.",
    empty_list_icon: EmptyListIcon,
    alt: "no review",
    primary_cta: {
      children: published_feedbacks.add_custom_feedback,
      onClick: (filterValues) => handleModalOpen(filterValues),
    },
    secondary_cta: { children: "Sample Published Feedback" },
    preview_image: constants.preview_published_feedbacks,
    children: `All feedback that you choose to show on your offering page, as well as any custom feedback you make for your offerings will be listed here. View the video to learn more.`,
  };

  return {
    //offerings
    fetchOfferings,
    offerings_processing,
    offerings,
    active_offerings,
    filter_offerings,

    //feedback questions
    fetchRatings,
    getFeedbackQuestions,
    ratings,
    overall_rating,
    processing,
    bookings_feedback_text,
    bookings_feedback_percentage,

    // user feedbacks
    handleAddToOffering,

    // published review
    review_payload,
    error_object,
    show_offerings,
    getReviewModalProps,
    show_modal,
    updateData,
    handleModalSave,
    MORE_ACTIONS,
    handleModalOpen,
    handleReviewDelete,
    show_delete_modal,
    handleModalClose,
    empty_state_props,

    // others
    is_desktop,
  };
};
