import React from "react";

import { IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";

import { Button } from "@my-scoot/component-library-legacy";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import styles from "./header.module.css";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { LEADERBOARD_LEARN_MORE_HREFS } from "../../leaderboard.data";
import { useHistory, useParams } from "react-router-dom";
import { app_route_ids, app_route_keys } from "constants/urlPaths";

const Header = ({ onClick, showCta, loading }) => {
  const isDesktop = useDesktopMediaQuery();

  const history = useHistory();
  const { listing_uuid } = useParams();

  const ButtonComponent = (
    <Button
      onClick={onClick}
      buttonWrapperClassName={styles.buttonWrapperClassName}
      fullWidth={!isDesktop}
      loading={loading}
    >
      Save changes
    </Button>
  );

  return (
    <>
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          {!isDesktop && (
            <IconButton
              onClick={() =>
                history.replace(
                  `/${app_route_ids[app_route_keys.manage_offering]}/${listing_uuid}`
                )
              }
            >
              <BackIcon />
            </IconButton>
          )}
          Leaderboard
        </div>

        <div>
          {showCta && isDesktop ? (
            ButtonComponent
          ) : (
            <LearnMoreCta href={LEADERBOARD_LEARN_MORE_HREFS.LEADERBOARD} />
          )}
        </div>
      </div>
      {showCta && !isDesktop && (
        <div className={styles.mobileCtaWrapper}>{ButtonComponent}</div>
      )}
    </>
  );
};

export default Header;
